var oldTar,
    timing = 500;

$(function() {

    function openSection() {
        // Read window.location.hash and redirect to corresponding site
        var AplCss,
        hash = window.location.hash.split('#')[1],
        targetSection = $('.prt_'+ hash +'_wrapper');

        if(hash == 'about'){
            AplCss  = {'top':0};
        }else if(hash == 'contact'){
            AplCss  = {'left':0};
        }else if(hash == 'services'){
            AplCss  = {'bottom':0 , 'top':0};
        }else if(hash == 'portfolio'){
            AplCss  = {'right':0};
        }

        oldTar = hash;

        targetSection.css('display', 'block');
        targetSection.animate(AplCss, timing);
    }

    function hide_section(){
        var AplCss,
            targetSection = $('.prt_'+ oldTar +'_wrapper');

		if(oldTar == 'about'){
			AplCss  = {'display':'none', 'top':'-100%'};
		}else if(oldTar == 'contact'){
			AplCss  = {'display':'none','left':'100%'};
		}else if(oldTar == 'services'){
			AplCss  = {'display':'none','bottom':'0' , 'top':'100%'};
		}else if(oldTar == 'portfolio'){
			AplCss  = {'display':'none','right':'100%'};
		}
		setTimeout(function(){ targetSection.css('display', 'none'); }, timing);
        targetSection.animate(AplCss, timing);
        
        oldTar = '';
	};

    $(".home-carousel").owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        autoHeight: true,
        autoplayTimeout: 9000,
        autoplaySpeed: 2000,
        startPosition: 0
    });

    $('span.copyright').prependTo('.home-carousel');

    $('.menu-toggle').click(function(e) {
        e.preventDefault();
        
        $(this).toggleClass('active');
    });

    $('.mobile-menu a').click(function(e) {
        hide_section();

        var AplCss,
            tar = $(this).attr('href').split('#')[1],
            targetSection = $('.prt_'+ tar +'_wrapper');
        
        $('.menu-toggle').removeClass('active');

		if(tar == 'about'){
			AplCss  = {'top':0};
		}else if(tar == 'contact'){
			AplCss  = {'left':0};
		}else if(tar == 'services'){
			AplCss  = {'bottom':0 , 'top':0};
		}else if(tar == 'portfolio'){
			AplCss  = {'right':0};
        }

        oldTar = tar;
        
		targetSection.css('display', 'block');
		targetSection.animate(AplCss, timing);
    });

    $('img#prt_close_tab').click(function() {
        $('img.prt_close').hide();
        hide_section();
    })

    // Triggers Sticky Class on Header of User scrolled at least X-Amount of Pixels 
    function setSticky(self) {
        var scrollDistance = self.scrollTop();

        if(scrollDistance > 5) {
            $('.prt_logo_wrapper').addClass('sticky');
        } else {
            $('.prt_logo_wrapper').removeClass('sticky');
        }
    }

    $('.prt_main_wrapper').scroll(function() {
        setSticky($(this));
    });

    $(document).scroll(function() {
        setSticky($(this));
    });

    openSection();

    $('.prt_footer_wrapper a.section_footer__contact').click(function(e) {
        hide_section();

        setTimeout(function() {
            AplCss  = {'left':0};
            targetSection = $('.prt_contact_wrapper');

            targetSection.css('display', 'block');
            targetSection.animate(AplCss, timing);

            oldTar = 'contact';
        }, 1);
    });
});
